import React, { useState, useEffect } from "react";
import { jsPDF } from "jspdf";
import { IoIosCloseCircleOutline } from "react-icons/io";

const ViewReceipt = ({ receiptDetails, onClose }) => {
    const [pdfSrc, setPdfSrc] = useState(null);

    useEffect(() => {
        generateReceiptPDF();
    }, [receiptDetails]);

    const generateReceiptPDF = () => {
        const doc = new jsPDF();
        const formattedDate = new Date(receiptDetails?.payment_date).toLocaleDateString();

        // Header
        doc.setFontSize(16);
        doc.text("Receipt", 105, 20, { align: "center" });

        // Basic Details
        doc.setFontSize(12);
        doc.text(`Receipt ID: ${receiptDetails?.document_id}`, 20, 40);
        doc.text(`Date: ${formattedDate}`, 20, 55);
        doc.text(`Payee: ${receiptDetails?.entity_id}`, 20, 70);
        doc.text(`Amount: ${parseFloat(receiptDetails?.amount).toLocaleString("en-US", {
            style: "currency",
            currency: "LKR",
        })}`, 20, 85);

        doc.text("Description:", 20, 100);
        doc.text(receiptDetails?.description || "N/A", 20, 115);

        // Linked Documents Section for "Receive" Type Payments
        if (receiptDetails?.payment_type === "Receive" && receiptDetails?.linked_documents?.length > 0) {
            doc.text("Payment Documents:", 20, 140);

            const startY = 150; // Starting position for the table
            const columnWidths = [40, 60, 50]; // Column widths for table

            // Table Headers
            doc.setFontSize(10);
            doc.text("Document Type", 20, startY);
            doc.text("Document ID", 70, startY);
            doc.text("Settled Amount", 130, startY);

            // Draw Rows for Linked Documents
            receiptDetails.linked_documents.forEach((docEntry, index) => {
                const rowY = startY + (index + 1) * 15; // Row spacing

                doc.text(docEntry.linked_type, 20, rowY);
                doc.text(docEntry.linked_id, 70, rowY);
                doc.text(parseFloat(docEntry.settled_amount).toLocaleString("en-US", {
                    style: "currency",
                    currency: "LKR",
                }), 130, rowY);
            });
        }

        // Footer
        doc.text("Thank you for your payment!", 105, 270, { align: "center" });

        // Generate Blob and URL for PDF
        const pdfBlob = doc.output("blob");
        const pdfUrl = URL.createObjectURL(pdfBlob);
        setPdfSrc(pdfUrl);
    };

    return (
        <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
            <div className="w-[60%] h-[90%] overflow-y-auto p-8 bg-white rounded-lg">
                <div className="flex items-center justify-between mb-6">
                    <h2 className="text-2xl font-semibold">View Receipt</h2>
                    <button
                        type="button"
                        onClick={onClose}
                        className="p-4 text-4xl text-gray-500 transition duration-300 hover:text-gray-700"
                    >
                        <IoIosCloseCircleOutline />
                    </button>
                </div>

                {/* PDF Preview */}
                {pdfSrc && (
                    <div className="mb-6">
                        <iframe
                            src={pdfSrc}
                            width="100%"
                            height="700px"
                            title="Receipt PDF Preview"
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default ViewReceipt;
