/** @format */

import {
  FiUser,
  FiUsers,
  FiArchive,
  FiClipboard,
  FiFileText,
  FiCheckSquare,
  FiMapPin,
  FiChevronDown,
  FiTruck,
} from "react-icons/fi";

import { FaCar } from "react-icons/fa";

import { MdOutlineHighQuality } from "react-icons/md";
import { HiUsers } from "react-icons/hi";
import { BsFillPersonVcardFill } from "react-icons/bs";
import { FaCashRegister } from "react-icons/fa";
import { FaWarehouse, FaPersonChalkboard } from "react-icons/fa6";
import { IoSettingsSharp } from "react-icons/io5";
import { FcApproval } from "react-icons/fc";
import { CiMoneyCheck1 } from "react-icons/ci";

const systemsList = [
  {
    _id: "1",
    name: "POS",
    url: "",
    icon: <FaCashRegister />,
    expandIcon: <FiChevronDown />,
    requiredPermissionsformainfeatures: ["1"],
    features: [
      {
        _id: "1.1",
        name: "POS",
        url: "/pos/pos",
        icon: <FiFileText />,
        requiredPermissionsforsubfeatures: ["100"],
      },
      {
        _id: "1.2",
        name: "POS BILLS",
        url: "/pos/bill",
        requiredPermissionsforsubfeatures: ["101"],
      },

      {
        _id: "1.3",
        name: "RETURN",
        url: "/return_home",
        requiredPermissionsforsubfeatures: ["101"],
      },
      {
        _id: "1.3",
        name: "DASHBOARD",
        url: "/pos/pos-dashboard",
        requiredPermissionsforsubfeatures: ["102"],
      },

      {
        _id: "1.5",
        name: "ITEMS",
        url: "/pos/pos-items",
        requiredPermissionsforsubfeatures: ["103"],
      },
    ],
  },
  {
    _id: "2",
    name: "INVENTORY",
    url: "",
    icon: <FiArchive />,
    expandIcon: <FiChevronDown />,
    requiredPermissionsformainfeatures: ["2"],
    features: [
      {
        _id: "2.1",
        name: "INVENTORY PURCHASE",
        url: "/inventory/purchase-nav",
        requiredPermissionsforsubfeatures: ["110"],
      },
      {
        _id: "2.2",
        name: "INVENTORY REQUEST",
        url: "/inventory/request-nav",
        requiredPermissionsforsubfeatures: ["111"],
      },
      {
        _id: "2.3",
        name: "INVENTORY DISPOSE",
        url: "/inventory/dispose",
        requiredPermissionsforsubfeatures: ["112"],
      },
      {
        _id: "2.4",
        name: "DISPOSED ITEMS",
        url: "/inventory/disposed",
        requiredPermissionsforsubfeatures: ["113"],
      },
      {
        _id: "2.5",
        name: "INVENTORY RETURN",
        url: "/inventory/return-request-nav",
        requiredPermissionsforsubfeatures: ["114"],
      },
      {
        _id: "2.6",
        name: "INVENTORY ADJUSTMENT",
        url: "/inventory/adjust",
        requiredPermissionsforsubfeatures: ["115"],
      },
      {
        _id: "2.7",
        name: "ADJUSTED ITEMS",
        url: "/inventory/adjusted",
        requiredPermissionsforsubfeatures: ["116"],
      },
      {
        _id: "2.8",
        name: "DASHBOARD",
        url: "/inventory/dashboardNav",
        requiredPermissionsforsubfeatures: ["117"],
      },
    ],
  },
  {
    _id: "3",
    name: "PRODUCTION",
    url: "",
    icon: <FaWarehouse />,
    expandIcon: <FiChevronDown />,
    requiredPermissionsformainfeatures: ["3"],
    features: [
      {
        _id: "3.1",
        url: "/recipe-nav",
        name: "Recipe",
        requiredPermissionsforsubfeatures: ["120"],
      },
      {
        _id: "3.2",
        name: "UNIT",
        url: "/make-units",
        requiredPermissionsforsubfeatures: ["121"],
      },
      {
        _id: "3.3",
        url: "/job-nav",
        name: "JOB",
        requiredPermissionsforsubfeatures: ["122"],
      },
      {
        _id: "3.4",
        name: "CREATED JOB LIST",
        url: "/created-job-list",
        requiredPermissionsforsubfeatures: ["123"],
      },
      {
        _id: "3.5",
        name: "PRODUCTION",
        url: "/start-production",
        requiredPermissionsforsubfeatures: ["124"],
      },
      {
        _id: "3.6",
        name: "MANAGE PRODUCTION",
        url: "/manage-production",
        requiredPermissionsforsubfeatures: ["125"],
      },

      {
        _id: "3.7",
        name: "WAREHOUSE",
        url: "/warehouse-nav",
        requiredPermissionsforsubfeatures: ["126"],
      },
    ],
  },

  {
    _id: "4",
    name: "HRIS",
    url: "",
    icon: <HiUsers />,
    expandIcon: <FiChevronDown />,
    requiredPermissionsformainfeatures: ["4"],
    features: [
      {
        _id: "4.8",
        name: "Dashboard",
        url: "/dashboard",
        icon: <FiFileText />,
        requiredPermissionsforsubfeatures: ["137"],
      },
      {
        _id: "4.1",
        name: "Employee",
        url: "/emp-nav",
        icon: <FiFileText />,
        requiredPermissionsforsubfeatures: ["130"],
      },
      {
        _id: "4.2",
        name: "Attendance",
        url: "/attendance",
        icon: <FiFileText />,
        requiredPermissionsforsubfeatures: ["131"],
      },
      {
        _id: "4.3",
        name: "Payroll",
        url: "/payments-menu",
        icon: <FiFileText />,
        requiredPermissionsforsubfeatures: ["132"],
      },
      {
        _id: "4.4",
        name: "Performance",
        url: "/performance-nav",
        icon: <FiFileText />,
        requiredPermissionsforsubfeatures: ["133"],
      },

      {
        _id: "4.6",
        name: "Leave Management",
        url: "/leaves",
        icon: <FiFileText />,
        requiredPermissionsforsubfeatures: ["135"],
      },
      {
        _id: "4.7",
        name: "Loan Information",
        url: "/view-loan-info",
        icon: <FiFileText />,
        requiredPermissionsforsubfeatures: ["136"],
      },
    ],
  },
  {
    _id: "5",
    name: "BRANCH",
    url: "",
    icon: <FiMapPin />,
    expandIcon: <FiChevronDown />,
    requiredPermissionsformainfeatures: ["5"],
    features: [
      {
        _id: "5.1",
        name: "ADD BRANCH",
        url: "/branches/add",
        requiredPermissionsforsubfeatures: ["140"],
      },
      {
        _id: "5.2",
        name: "VIEW BRANCH",
        url: "/branches/view",
        requiredPermissionsforsubfeatures: ["141"],
      },
    ],
  },

  {
    _id: "6",
    name: "POS ADMIN",
    url: "",
    icon: <FiUsers />,
    expandIcon: <FiChevronDown />,
    requiredPermissionsformainfeatures: ["6"],
    features: [
      {
        _id: "6.1",
        name: "ADD CUSTOMER",
        url: "/customer/add",
        requiredPermissionsforsubfeatures: ["150"],
      },
      {
        _id: "6.2",
        name: "UPDATE/VIEW CUSTOMER",
        url: "/customer/update",
        requiredPermissionsforsubfeatures: ["151"],
      },
      {
        _id: "6.3",
        name: "CREDIT CLEARANCE",
        url: "/customer/credit",
        requiredPermissionsforsubfeatures: ["152"],
      },

      {
        _id: "6.4",
        name: "CUSTOMER TYPE",
        url: "/customer-type",
        requiredPermissionsforsubfeatures: ["153"],
      },

      {
        _id: "6.5",
        name: "VIEW & UPDATE CUSTOMER TYPE",
        url: "/view-update-customer",
        requiredPermissionsforsubfeatures: ["154"],
      },

      {
        _id: "6.5",
        name: "POS REPORT",
        url: "/pos_report",
        requiredPermissionsforsubfeatures: ["154"],
      },
    ],
  },
  {
    _id: "7",
    name: "INVENTORY TRANSFER",
    url: "",
    icon: <FiClipboard />,
    expandIcon: <FiChevronDown />,
    requiredPermissionsformainfeatures: ["7"],
    features: [
      {
        _id: "7.1",
        name: "REQUEST ITEMS",
        url: "/transfer/request",
        requiredPermissionsforsubfeatures: ["160"],
      },
      {
        _id: "7.2",
        name: "TRANSFER REQUEST",
        url: "/transfer/send",
        requiredPermissionsforsubfeatures: ["161"],
      },
    ],
  },
  {
    _id: "8",
    name: "SUPPLIER",
    url: "",
    icon: <FiClipboard />,
    expandIcon: <FiChevronDown />,
    requiredPermissionsformainfeatures: ["8"],
    features: [
      {
        _id: "8.1",
        name: "ADD SUPPLIER",
        url: "/supplier/add",
        requiredPermissionsforsubfeatures: ["170"],
      },
      {
        _id: "8.2",
        name: "UPDATE SUPPLIER",
        url: "/supplier/update",
        requiredPermissionsforsubfeatures: ["171"],
      },
    ],
  },
  {
    _id: "9",
    name: "ITEM CATEGORY",
    url: "",
    icon: <FiCheckSquare />,
    expandIcon: <FiChevronDown />,
    requiredPermissionsformainfeatures: ["9"],
    features: [
      {
        _id: "9.1",
        name: "ADD ITEM CATEGORY",
        url: "/itemcategory/add",
        requiredPermissionsforsubfeatures: ["180"],
      },
      // {
      //   _id: "9.2",
      //   name: "UPDATE ITEM CATEGORY",
      //   url: "/itemcategory/update",
      //   requiredPermissionsforsubfeatures: ["181"],
      // },
      {
        _id: "9.2",
        name: "VIEW ITEM CATEGORY",
        url: "/itemcategory/view",
        requiredPermissionsforsubfeatures: ["181"],
      },
    ],
  },
  {
    _id: "10",
    name: "ITEM",
    url: "",
    icon: <FiCheckSquare />,
    expandIcon: <FiChevronDown />,
    requiredPermissionsformainfeatures: ["10"],
    features: [
      {
        _id: "10.1",
        name: "ADD ITEM",
        url: "/items/create",
        requiredPermissionsforsubfeatures: ["190"],
      },
      // {
      //   _id: "10.2",
      //   name: "UPDATE ITEM",
      //   url: "/items/update",
      //   requiredPermissionsforsubfeatures: ["191"],
      // },
      {
        _id: "10.2",
        name: "VIEW ITEM",
        url: "/items/view",
        requiredPermissionsforsubfeatures: ["191"],
      },
    ],
  },
  {
    _id: "11",
    name: "STORES",
    url: "",
    icon: <FiCheckSquare />,
    expandIcon: <FiChevronDown />,
    requiredPermissionsformainfeatures: ["11"],
    features: [
      {
        _id: "11.1",
        name: "ADD STORE",
        url: "/stores/add",
        requiredPermissionsforsubfeatures: ["200"],
      },
      // {
      //   _id: "11.2",
      //   name: "UPDATE STORE",
      //   url: "/stores/update",
      //   requiredPermissionsforsubfeatures: ["201"],
      // },
      {
        _id: "11.2",
        name: "VIEW STORE",
        url: "/stores/view",
        requiredPermissionsforsubfeatures: ["201"],
      },
    ],
  },
  {
    _id: "12",
    name: "REPORTS",
    url: "",
    icon: <FiCheckSquare />,
    expandIcon: <FiChevronDown />,
    requiredPermissionsformainfeatures: ["12"],
    features: [
      {
        _id: "12.1",
        name: "ITEMS",
        url: "/reports/item",
        requiredPermissionsforsubfeatures: ["210"],
      },
      {
        _id: "12.2",
        name: "CATEGORY",
        url: "/reports/category",
        requiredPermissionsforsubfeatures: ["211"],
      },
    ],
  },
  // {
  //   _id: "13",
  //   name: "DESIGNATION",
  //   url: "",
  //   icon: <BsFillPersonVcardFill />,
  //   expandIcon: <FiChevronDown />,
  //   requiredPermissionsformainfeatures: ["13"],
  //   features: [
  //     {
  //       _id: "13.1",
  //       name: "ADD DESIGNATION",
  //       url: "/designation/add",
  //       requiredPermissionsforsubfeatures: ["220"],
  //     },
  //     {
  //       _id: "13.2",
  //       name: "VIEW & UPDATE DESIGNATION",
  //       url: "/designation/update",
  //       requiredPermissionsforsubfeatures: ["221"],
  //     },
  //   ],
  // },
  {
    _id: "14",
    name: "TRAINING TYPE",
    url: "",
    icon: <FaPersonChalkboard />,
    expandIcon: <FiChevronDown />,
    requiredPermissionsformainfeatures: ["14"],
    features: [
      {
        _id: "14.1",
        name: "ADD TRAINING TYPE",
        url: "/trainingtype/add",
        requiredPermissionsforsubfeatures: ["230"],
      },
      {
        _id: "14.2",
        name: "VIEW & UPDATE TRAINING TYPE DESCRIPTION",
        url: "/trainingtype/update",
        requiredPermissionsforsubfeatures: ["231"],
      },
    ],
  },
  {
    _id: "15",
    name: "SUPPLY CHAIN",
    url: "",
    icon: <FiTruck />,
    expandIcon: <FiChevronDown />,
    requiredPermissionsformainfeatures: ["15"],
    features: [
      {
        _id: "15.1",
        name: "Dashboard",
        url: "/supply/dashboard",
        icon: <FiFileText />,
        requiredPermissionsforsubfeatures: ["240"],
      },
      {
        _id: "15.2",
        name: "PURCHASE REQUEST",
        url: "/purchase/request",
        icon: <FiFileText />,
        requiredPermissionsforsubfeatures: ["241"],
      },
      {
        _id: "15.3",
        name: "DECISIONS",
        url: "/supply/decisions",
        icon: <FiFileText />,
        requiredPermissionsforsubfeatures: ["242"],
      },
      {
        _id: "15.4",
        name: "SUPPLIERS",
        url: "/suppliers/supply",
        icon: <FiFileText />,
        requiredPermissionsforsubfeatures: ["243"],
      },
      {
        _id: "15.5",
        name: "COMMITTEES",
        url: "/committees/home",
        icon: <FiFileText />,
        requiredPermissionsforsubfeatures: ["244"],
      },
      {
        _id: "15.6",
        name: "PURCHASE ORDER",
        url: "/purchase-orders",
        icon: <FiFileText />,
        requiredPermissionsforsubfeatures: ["245"],
      },
      {
        _id: "15.7",
        name: "VOUCHERS",
        url: "/vouchers",
        icon: <FiFileText />,
        requiredPermissionsforsubfeatures: ["246"],
      },
    ],
  },
  {
    _id: "16",
    name: "SETTINGS",
    icon: <IoSettingsSharp />,
    expandIcon: <FiChevronDown />,
    requiredPermissionsformainfeatures: ["16"],
    features: [
      {
        _id: "16.1",
        name: "USERS PERMISSION",
        url: "/settings_user",
        icon: <FiFileText />,
        requiredPermissionsforsubfeatures: ["250"],
      },

      {
        _id: "16.2",
        name: "USER MANAGEMENT",
        url: "/user_management",
        icon: <FiFileText />,
        requiredPermissionsforsubfeatures: ["251"],
      },
      {
        _id: "1.6",
        name: "CREATE POS USER",
        url: "/pos/create-user",
        requiredPermissionsforsubfeatures: ["104"],
      },

      {
        _id: "1.7",
        name: "DESIGNATION",
        url: "/designation",
        requiredPermissionsforsubfeatures: ["104"],
      },
    ],
  },

  //approval
  {
    _id: "17",
    name: "Approval Process",
    icon: <FcApproval />,
    expandIcon: <FiChevronDown />,
    requiredPermissionsformainfeatures: ["17"],
    features: [
      {
        _id: "17.1",
        name: "Approval One",
        url: "/approve-1-modules",
        icon: <FiFileText />,
        requiredPermissionsforsubfeatures: ["260"],
      },

      {
        _id: "17.2",
        name: "Approval Two",
        url: "/approve-2-modules",
        icon: <FiFileText />,
        requiredPermissionsforsubfeatures: ["261"],
      },
      {
        _id: "17.3",
        name: "Approval Three",
        url: "/approve-3-modules",
        icon: <FiFileText />,
        requiredPermissionsforsubfeatures: ["262"],
      },
    ],
  },
  //Branch Req
  {
    _id: "18",
    name: "Branch Request",
    icon: <FiArchive />,
    url: "/branch-req-nav",
    expandIcon: <FiChevronDown />,
    requiredPermissionsformainfeatures: ["270"],
  },
  // Quality Management
  {
    _id: "19",
    name: "Quality Management",

    icon: <MdOutlineHighQuality />,
    expandIcon: <FiChevronDown />,
    requiredPermissionsformainfeatures: ["19"],
    features: [
      {
        _id: "19.1",
        url: "/quality-management/sample-allocation",
        name: "Sample Allocation",
        requiredPermissionsforsubfeatures: ["300"],
      },
      {
        _id: "19.2",
        url: "/quality-management/sample-testing",
        name: "Sample Testing",
        requiredPermissionsforsubfeatures: ["301"],
      },
      {
        _id: "19.3",
        name: "Sample Test Reports",
        url: "/quality-management/sample-test-reports",
        requiredPermissionsforsubfeatures: ["302"],
      },
      {
        _id: "19.4",
        name: "Disposal Process",
        url: "/quality-management/disposal-process",
        requiredPermissionsforsubfeatures: ["303"],
      },
      {
        _id: "19.5",
        name: "Training Management",
        url: "/quality-management/training-management",
        requiredPermissionsforsubfeatures: ["304"],
      },

      // change permissions Id
      {
        _id: "19.6",
        name: "Training Certifications",
        url: "/quality-management/training-certifications",
        requiredPermissionsforsubfeatures: ["305"],
      },

      {
        _id: "19.7",
        name: "Approval Process 01",
        url: "/quality-management/approval-Stage-01-Home",
        requiredPermissionsforsubfeatures: ["305"],
      },

      {
        _id: "19.8",
        name: "Approval Process 02",
        url: "/quality-management/approval-Stage-02-Home",
        requiredPermissionsforsubfeatures: ["305"],
      },
    ],
  },

  // Finance Management
  {
    _id: "20",
    name: "Finance Management",
    url: "https://www.microsoft.com/en-us/dynamics-365/products/business-central",
    icon: <MdOutlineHighQuality />,
    expandIcon: <FiChevronDown />,
    requiredPermissionsformainfeatures: ["20"],
    features: [],
  },

  // Finance Management
  {
    _id: "21",
    name: "Finance Management",

    icon: <CiMoneyCheck1 />,
    expandIcon: <FiChevronDown />,
    requiredPermissionsformainfeatures: ["21"],
    features: [
      {
        _id: "21.1",
        url: "/finance-management/dashbord",
        name: "Dashboard",
        requiredPermissionsforsubfeatures: ["306"],
      },
      {
        _id: "21.2",
        url: "/finance-management/chart-of-accounts",
        name: "Chart Of Accounts",
        requiredPermissionsforsubfeatures: ["307"],
      },
      {
        _id: "21.3",
        url: "/finance-management/payment-entry",
        name: "Payment Entry",
        requiredPermissionsforsubfeatures: ["308"],
      },
      {
        _id: "21.4",
        url: "/finance-management/journal-entry",
        name: "Journal Entry",
        requiredPermissionsforsubfeatures: ["309"],
      },
      {
        _id: "21.5",
        url: "/finance-management/purchase-invoice",
        name: "Purchase Invoice",
        requiredPermissionsforsubfeatures: ["310"],
      },

      {
        _id: "21.6",
        url: "/finance-management/sales-invoice",
        name: "Sales Invoice",
        requiredPermissionsforsubfeatures: ["311"],
      },

      {
        _id: "21.16",
        url: "/finance-management/billing",  
        name: "Billing",
        requiredPermissionsforsubfeatures: ["320"],
      },
      {
        _id: "21.7",
        url: "/finance-management/accounts-payables",
        name: "Accounts Payables",
        requiredPermissionsforsubfeatures: ["312"],
      },

      {
        _id: "21.8",
        url: "/finance-management/account-receivable",
        name: "Account Receivable",
        requiredPermissionsforsubfeatures: ["313"],
      },

      // {
      //   _id: "21.10",
      //   url: "/finance-management/trial-balance",
      //   name: "Trial Balance",
      //   requiredPermissionsforsubfeatures: ["315"],
      // },

      {
        _id: "21.11",
        url: "/finance-management/financial-reports/profit-and-loss",
        name: "Financial Reports",
        requiredPermissionsforsubfeatures: ["316"],
      },
      
      {
        _id: "21.13",
        url: "/finance-management/bank-reconciliation-tool",  
        name: "Bank Reconciliation Tool",
        requiredPermissionsforsubfeatures: ["318"],
      },

      {
        _id: "21.14",
        url: "/finance-management/budget-variation",  
        name: "Budget Variation",
        requiredPermissionsforsubfeatures: ["319"],
      },

      
      {
        _id: "21.17",
        url: "/finance-management/categories",  
        name: "Assets Category",
        requiredPermissionsforsubfeatures: ["320"],
      },

      {
        _id: "21.15",
        url: "/finance-management/assets",  
        name: "Assets",
        requiredPermissionsforsubfeatures: ["320"],
      },

      {
        _id: "21.12",
        url: "/finance-management/account-authorization",
        name: "Account Authorization",
        requiredPermissionsforsubfeatures: ["317"],
      },
    ],
  },

  // Vehicle Fleet
  // {
  //   _id: "22",
  //   name: "Vehicle Fleet",

  //   icon: <FaCar/>,
  //   expandIcon: <FiChevronDown />,
  //   requiredPermissionsformainfeatures: ["21"],
  //   features: [
  //     {
  //       _id: "22.1",
  //       url: "/vehicle-information-management",
  //       name: "Vehicle Information Management",
  //       requiredPermissionsforsubfeatures: ["300"],
  //     },
  //     {
  //       _id: "22.2",
  //       url: "/current-vehicle-dispatches",
  //       name: "Current Vehicle Dispatches",
  //       requiredPermissionsforsubfeatures: ["300"],
  //     },

  //     {
  //       _id: "22.3",
  //       url: "/current-vehicle-schedule",
  //       name: "Current Vehicle Schedule",
  //       requiredPermissionsforsubfeatures: ["300"],
  //     },

  //     {
  //       _id: "22.4",
  //       url: "/fuel-manegemnt",
  //       name: "Fuel Manegemnt",
  //       requiredPermissionsforsubfeatures: ["300"],
  //     },

  //     {
  //       _id: "22.5",
  //       url: "/maintenance-manegement",
  //       name: "Maintenance & Repairs",
  //       requiredPermissionsforsubfeatures: ["300"],
  //     },

  //     {
  //       _id: "22.6",
  //       url: "/inventory-management",
  //       name: "Inventory Management",
  //       requiredPermissionsforsubfeatures: ["300"],
  //     },

  //     {
  //       _id: "22.7",
  //       url: "/performance",
  //       name: "Performance",
  //       requiredPermissionsforsubfeatures: ["300"],
  //     },

  //     {
  //       _id: "22.8",
  //       url: "/compliance-reporting",
  //       name: "Compliance & Reporting",
  //       requiredPermissionsforsubfeatures: ["300"],
  //     },

  //     {
  //       _id: "22.9",
  //       url: "/Approval-process-01",
  //       name: "Approval Process 01",
  //       requiredPermissionsforsubfeatures: ["300"],
  //     },
  //     {
  //       _id: "22.10",
  //       url: "/Approval-process-02",
  //       name: "Approval Process 02",
  //       requiredPermissionsforsubfeatures: ["300"],
  //     },
  //     {
  //       _id: "22.11",
  //       url: "/Approval-process-03",
  //       name: "Approval Process 03",
  //       requiredPermissionsforsubfeatures: ["300"],
  //     },
  //   ],
  // },
];

export default systemsList;
