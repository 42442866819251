import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactSelect from "react-select";
import Cookies from "js-cookie";

const AccountAuthorizationCashFlow = () => {
    const [sections, setSections] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [selectedAccounts, setSelectedAccounts] = useState({});
    const [notification, setNotification] = useState(null);
    const backendUrl = process.env.REACT_APP_BACKEND_URL_FINANCE;
    //const backendUrl = `http://localhost:8089/v1/finance`;

    // Fetch sections for cash flow categories
    const fetchSections = async () => {
        try {
            const response = await axios.get(`${backendUrl}/account/accountAuthorization/cashFlow/getCashFlowSections`);
            setSections(response.data);
        } catch (error) {
            console.error("Error fetching sections:", error);
        }
    };

    // Fetch financial accounts
    const fetchAccounts = async () => {
        try {
            const response = await axios.get(`${backendUrl}/account/getAllAccountsByFilters`, {
                params: { is_enabled: 1 },
            });
            setAccounts(
                response.data.data.map((acc) => ({
                    value: acc.account_id,
                    label: `${acc.account_code} - ${acc.account_name}`,
                }))
            );
        } catch (error) {
            console.error("Error fetching accounts:", error);
        }
    };

    // Fetch selected accounts for cash flow sections
    const fetchSelectedAccounts = async () => {
        try {
            const response = await axios.get(
                `${backendUrl}/account/accountAuthorization/cashFlow/getCashFlowSectionAccounts`
            );
            const { sections } = response.data;

            // Transform sections to populate selectedAccounts state
            setSelectedAccounts(
                Object.entries(sections).reduce((acc, [sectionId, accountIds]) => {
                    acc[sectionId] = accountIds;
                    return acc;
                }, {})
            );
        } catch (error) {
            console.error("Error fetching selected accounts:", error);
        }
    };

    // Handle account selection
    const handleAccountChange = (sectionId, selectedOptions) => {
        setSelectedAccounts((prev) => ({
            ...prev,
            [sectionId]: selectedOptions.map((option) => option.value),
        }));
    };

    // Submit selected accounts
    const handleSubmit = async () => {
        try {
            const actionBy = Cookies.get("employee_no");

            // Build payload ensuring all section IDs are included
            const sectionsPayload = sections.reduce((acc, section) => {
                acc[section.id] = selectedAccounts[section.id] || [];
                return acc;
            }, {});

            const payload = {
                action_by: actionBy,
                sections: sectionsPayload,
            };

            await axios.post(
                `${backendUrl}/account/accountAuthorization/cashFlow/updateCashFlowSectionAccounts`,
                payload
            );
            setNotification({ type: "success", message: "Sections updated successfully!" });
        } catch (error) {
            console.error("Error submitting sections:", error);
            setNotification({ type: "error", message: "Error updating sections!" });
        }
    };

    useEffect(() => {
        fetchSections();
        fetchAccounts();
        fetchSelectedAccounts();
    }, []);

    return (
        <div className="container mx-auto p-6 pt-0 bg-gray-50">
            {notification && (
                <div
                    className={`p-4 mb-4 rounded ${
                        notification.type === "success" ? "bg-green-100 text-green-800" : "bg-red-100 text-red-800"
                    }`}
                >
                    {notification.message}
                </div>
            )}
            <h4 className="text-2xl font-bold text-center mb-6">
                Configure Cash Flow Sections
            </h4>

            <div className="bg-white rounded-lg shadow p-6">
                {sections.map((section) => (
                    <div key={section.id} className="mb-6">
                        <label className="block mb-2 text-sm font-medium">
                            Accounts for {section.section_name}
                        </label>
                        <ReactSelect
                            options={accounts}
                            isMulti
                            value={
                                accounts.filter((acc) =>
                                    selectedAccounts[section.id]?.includes(acc.value)
                                )
                            }
                            onChange={(selectedOptions) =>
                                handleAccountChange(section.id, selectedOptions || [])
                            }
                            placeholder="Select Accounts"
                        />
                    </div>
                ))}

                <div className="flex justify-end">
                    <button
                        onClick={handleSubmit}
                        className="mt-4 bg-blue-600 text-white px-4 py-2 rounded shadow hover:bg-blue-700"
                    >
                        Submit
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AccountAuthorizationCashFlow;
