/** @format */

import React from "react";
import usePermissions from "../../../components/permissions/permission";
import { Link } from "react-router-dom";

const Approve_1 = () => {
  const { hasPermission } = usePermissions();
  return (
    <div className="h-screen overflow-y-auto bg-background">
      <div className="ml-[6%] mt-[5%] mr-[3%]">
        <p className="text-headerColor text-[28px] font-semibold">
          Approval Stage One
        </p>
        <div className="grid grid-cols-7 gap-8 mt-8">
          {/* card1 */}
          {hasPermission(1980) && (
            <div className="bg-white w-[220px] h-[200px] rounded-[30px]">
              <div className="text-[20px] font-bold ml-[20px] mt-[30px]">
                <p className="text-primary text-[25px]">HRIS</p>
                <p>Leave Approvals</p>
                <div>
                  <Link to="/approval1-info">
                    <button className="bg-primary text-white p-2 rounded-[20px] mt-12">
                      View
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          )}
          {/* card2 */}
          {hasPermission(1981) && (
            <div className="bg-white w-[220px] h-[200px] rounded-[30px]">
              <div className="text-[20px] font-bold ml-[20px] mt-[30px]">
                <p className="text-primary text-[25px]">HRIS</p>
                <p>Employee Data Approvals</p>
                <div>
                  <Link to="/approval1-user">
                    <button className="bg-primary text-white p-2 rounded-[20px] mt-5">
                      View
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          )}

          {/* card3 */}
          {hasPermission(1982) && (
            <div className="bg-white w-[220px] h-[200px] rounded-[30px]">
              <div className="text-[20px] font-bold ml-[20px] mt-[30px]">
                <p className="text-primary text-[25px]">POS</p>
                <p>Add Registered Customer</p>
                <div>
                  <button className="bg-primary text-white p-2 rounded-[20px] mt-5">
                    View
                  </button>
                </div>
              </div>
            </div>
          )}
          {/* card4 */}
          {hasPermission(1983) && (
            <div className="bg-white w-[220px] h-[200px] rounded-[30px]">
              <div className="text-[20px] font-bold ml-[20px] mt-[30px]">
                <p className="text-primary text-[25px]">POS</p>
                <p>Credit Clearanace Approvals</p>
                <div>
                  <button className="bg-primary text-white p-2 rounded-[20px] mt-5">
                    View
                  </button>
                </div>
              </div>
            </div>
          )}
          {/* card5 */}
          {hasPermission(1984) && (
            <div className="bg-white w-[220px] h-[200px] rounded-[30px]">
              <div className="text-[20px] font-bold ml-[20px] mt-[30px]">
                <p className="text-primary text-[25px]">Inventory</p>
                <p>Inventory Dispose Approvals</p>
                <div>
                  <Link to="/approval1-dispose">
                    <button className="bg-primary text-white p-2 rounded-[20px] mt-5">
                      View
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          )}

          {/* card6 */}
          {hasPermission(1985) && (
            <div className="bg-white w-[220px] h-[200px] rounded-[30px]">
              <div className="text-[20px] font-bold ml-[20px] mt-[30px]">
                <p className="text-primary text-[25px]">Inventory</p>
                <p>Inventory Adjust Approvals</p>
                <div>
                  <Link to="/approval1-adjusted">
                    <button className="bg-primary text-white p-2 rounded-[20px] mt-5">
                      View
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          )}

          {/* change id  */}

          {/* card7 */}
          {hasPermission(1986) && (
            <div className="bg-white w-[220px] h-[200px] rounded-[30px]">
              <div className="text-[20px] font-bold ml-[20px] mt-[30px]">
                <p className="text-primary text-[25px]">QM</p>
                <p>Sample Testing Report</p>
                <div>
                  <Link to="/quality-management/approva1-approvaSampleTesting">
                    <button className="bg-primary text-white p-2 rounded-[20px] mt-5">
                      View
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          )}

          {/* change id  */}
          {/* card7 */}
          {hasPermission(1986) && (
            <div className="bg-white w-[220px] h-[200px] rounded-[30px]">
              <div className="text-[20px] font-bold ml-[20px] mt-[30px]">
                <p className="text-primary text-[25px]">QM</p>
                <p>Manual Disposal</p>
                <div>
                  <Link to="/quality-management/approva1-approvaManualDisposal">
                    <button className="bg-primary text-white p-2 rounded-[20px] mt-5">
                      View
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          )}


          {/* card9 */}
          {hasPermission(1986) && (
            <div className="bg-white w-[220px] h-[200px] rounded-[30px]">
              <div className="text-[20px] font-bold ml-[20px] mt-[30px]">
                <p className="text-primary text-[25px]">HRIS</p>
                <p>Overtime</p>
                <p>(Approval Step)</p>
                <div>
                  <Link to="/overtime-approval">
                    <button className="bg-primary text-white p-2 rounded-[20px] mt-5">
                      View
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          )}


          {/* card9 */}
          {hasPermission(1986) && (
            <div className="bg-white w-[220px] h-[200px] rounded-[30px]">
              <div className="text-[20px] font-bold ml-[20px] mt-[30px]">
                <p className="text-primary text-[25px]">HRIS</p>
                <p>Attendance Edit</p>

                <div>
                  <Link to="/attendance_edit_approval1">
                    <button className="bg-primary text-white p-2 rounded-[20px] mt-5">
                      View
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          )}

          <div className="bg-white w-[220px] h-[200px] rounded-[30px]">
            <div className="text-[20px] font-bold ml-[20px] mt-[30px]">
              <p className="text-primary text-[25px]">Generated</p>
              <p>Payroll</p>

              <div>
                <Link to="/genarated_payroll_approve">
                  <button className="bg-primary text-white p-2 rounded-[20px] mt-5">
                    View
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Approve_1;
