import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactSelect from "react-select";
import Cookies from "js-cookie";
import Notification from "../../../components/notification/notification";

const AccountAuthorizationPos = () => {
    const [branches, setBranches] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [selectedAccounts, setSelectedAccounts] = useState({}); // Object to store selected accounts
    const [defaultAccounts, setDefaultAccounts] = useState({}); // Object to store initial account configs
    const [notification, setNotification] = useState({ message: "", type: "" });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentBranchId, setCurrentBranchId] = useState(null);
    //const backendUrl = `http://localhost:8089/v1/finance`;
    const backendUrl = process.env.REACT_APP_BACKEND_URL_FINANCE;
    const backendUrlMain = process.env.REACT_APP_API_ENDPOINT;

    // Fetch branches
    const fetchBranches = async () => {
        try {
            const response = await axios.get(`${backendUrlMain}/v1/inventory/branch/all/Branch/ACTIVE`);
            setBranches(response.data);
        } catch (error) {
            console.error("Error fetching branches:", error);
        }
    };

    // Fetch financial accounts
    const fetchAccounts = async () => {
        try {
            const response = await axios.get(`${backendUrl}/account/getAllAccountsByFilters`, {
                params: { is_enabled: 1 },
            });
            setAccounts(
                response.data.data.map((acc) => ({
                    value: acc.account_id,
                    label: `${acc.account_code} - ${acc.account_name}`,
                }))
            );
        } catch (error) {
            console.error("Error fetching accounts:", error);
        }
    };

    // Fetch existing account configurations for branches
    const fetchBranchAccountConfigs = async () => {
        try {
            const response = await axios.get(`${backendUrl}/account/accountAuthorization/branch/getBranchAccConfigs`);
            const configs = response.data;

            // Map configs to selectedAccounts state
            const mappedSelectedAccounts = configs.reduce((acc, config) => {
                const { branch_id, ...fields } = config;
                acc[branch_id] = fields;
                return acc;
            }, {});

            // Map configs to defaultAccounts state for tracking changes
            const mappedDefaultAccounts = configs.reduce((acc, config) => {
                const { branch_id, ...fields } = config;
                acc[branch_id] = fields;
                return acc;
            }, {});

            setSelectedAccounts(mappedSelectedAccounts);
            setDefaultAccounts(mappedDefaultAccounts);
        } catch (error) {
            console.error("Error fetching branch account configs:", error);
        }
    };

    // Handle account selection
    const handleAccountChange = (branchId, field, value) => {
        setSelectedAccounts((prev) => ({
            ...prev,
            [branchId]: {
                ...prev[branchId],
                [field]: value, // Update value if selected
            },
        }));
    };

    // Open confirmation modal
    const openModal = (branchId) => {
        setCurrentBranchId(branchId);
        setIsModalOpen(true);
    };

    // Close confirmation modal
    const closeModal = () => {
        setIsModalOpen(false);
        setCurrentBranchId(null);
    };

    // Prepare payload for request
    const preparePayload = (branchId) => {
        const selectedBranchAccounts = selectedAccounts[branchId] || {};
        const defaultBranchAccounts = defaultAccounts[branchId] || {};

        // Combine selected and default accounts, handling unselected fields
        const payload = {
            branch_id: branchId,
            ...Object.keys({ ...selectedBranchAccounts, ...defaultBranchAccounts }).reduce((acc, field) => {
                acc[field] = selectedBranchAccounts[field] !== undefined ? selectedBranchAccounts[field] : null; // Set null for unselected fields
                return acc;
            }, {}),
            action_by: Cookies.get("employee_no"),
        };

        return payload;
    };

    // Save selected accounts for a specific branch
    const handleSubmit = async () => {
        const payload = preparePayload(currentBranchId);

        try {
            await axios.post(`${backendUrl}/account/accountAuthorization/branch/updateBranchWiseAccAuth`, payload);
            setNotification({ message: "Accounts updated successfully!", type: "success" });
        } catch (error) {
            console.error("Error updating accounts:", error);
            setNotification({ message: "Error updating accounts!", type: "error" });
        } finally {
            closeModal();
        }
    };

    useEffect(() => {
        fetchBranches();
        fetchAccounts();
        fetchBranchAccountConfigs(); // Fetch existing configs
    }, []);

    return (
        <div>
            <Notification message={notification.message} type={notification.type} onClose={() => setNotification({})}/>
            <h4 className="text-2xl font-bold text-center mb-6">
                Configure branch wise accounts for automation scenarios
            </h4>
            {branches.map((branch) => (
                <div key={branch._id} className="mb-6 p-5 bg-white rounded-lg shadow">
                    <h2 className="text-2xl font-semibold">{branch.branch_name}</h2>
                    <div className="grid grid-cols-2 gap-4 mt-4">
                        {[
                            "POS_add_sale_debit_cash",
                            "POS_add_sale_debit_card",
                            "POS_add_sale_debit_credit",
                            "POS_add_sale_credit_revenue",
                            "POS_add_cost_debit",
                            "POS_add_asset_credit",
                            "POS_return_asset_debit",
                            "POS_return_cost_credit",
                        ].map((field) => (
                            <div key={field}>
                                <label className="block mb-2 text-sm font-medium">{field.replace(/_/g, " ")}</label>
                                <ReactSelect
                                    options={accounts}
                                    value={accounts.find(
                                        (acc) => acc.value === selectedAccounts[branch._id]?.[field]
                                    )}
                                    onChange={(option) => handleAccountChange(branch._id, field, option?.value)}
                                    isClearable
                                    placeholder="Select Account"
                                />
                            </div>
                        ))}
                    </div>
                    <div className="flex justify-end">
                        <button
                            onClick={() => openModal(branch._id)}
                            className="mt-4 bg-blue-600 text-white px-4 py-2 rounded shadow hover:bg-blue-700"
                        >
                            Submit
                        </button>
                    </div>
                </div>
            ))}

            {/* Confirmation Modal */}
            {isModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded shadow-lg w-96">
                        <h2 className="text-lg font-bold mb-4">Confirm Submission</h2>
                        <p>Are you sure you want to submit the selected accounts for this branch?</p>
                        <div className="mt-6 flex justify-end space-x-4">
                            <button
                                onClick={closeModal}
                                className="bg-gray-300 text-gray-800 px-4 py-2 rounded hover:bg-gray-400"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleSubmit}
                                className="bg-blue-600 text-white px-4 py-2 rounded shadow hover:bg-blue-700"
                            >
                                Confirm
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AccountAuthorizationPos;
