import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import ReactSelect from "react-select";

function AssetsRevaluation({ row }) {
  const backendUrl = process.env.REACT_APP_BACKEND_URL_FINANCE;
  const userId = Cookies.get("employee_no");

  const [assets, setAssets] = useState([]); // State for storing assets if needed
  const [accounts, setAccounts] = useState([]); // State for accounts
  const [departments, setDepartments] = useState([]); // State for departments
  const [parentNames, setParentNames] = useState([]); // State for parent names
  const [formData, setFormData] = useState({
    id: "",
    assets_id: "",
    parent_asset_name: "",
    parent_department: "",
    current_value: "",
    children: [
      {
        id: "",
        assets_id: "",
        child_asset_name: "",
        child_department: "",
        current_value: "",
      },
    ],
  });

  useEffect(() => {
    const fetchAssets = async () => {
      try {
        const response = await axios.get(
          `${backendUrl}/assets/assets/getAssetsRevaluation`,
          {
            params: { assets_id: row?.id }, // Fetch using the asset_id passed from `row`
          }
        );

        const assetData = response.data; // Assuming the response contains asset details
        console.log("response data: ", assetData);

        // Check if response data is not empty and set the form data
        if (assetData && assetData.length > 0) {
          const asset = assetData[0]; // Assuming assetData is an array, and we are interested in the first element

          // Update formData with the asset and child details
          setFormData({
            id: asset.id,
            assets_id: asset.assets_id,
            parent_asset_name: asset.parent_asset_name || "",
            parent_department: asset.parent_department || "",
            current_value: asset.current_value || "",
            children: asset.children
              ? asset.children.map((child) => ({
                  id: child.id || "",
                  assets_id: child.assets_id || "",
                  child_asset_name: child.child_asset_name || "",
                  child_department: child.child_department || "",
                  current_value: child.current_value || "",
                }))
              : [],
          });
        }
      } catch (error) {
        console.error("Error fetching asset data:", error);
      }
    };

    if (row?.id) {
      fetchAssets();
    }
  }, [row, backendUrl]);

  // Fetch accounts
  useEffect(() => {
    const fetchAccounts = async () => {
      try {
        const response = await axios.get(
          `${backendUrl}/account/getAllAccountsByFilters`,
          { params: { is_enabled: 1 } }
        );
        const formattedAccounts = response.data.data.map((item) => ({
          id: item.account_id,
          name: `${item.account_code} - ${item.account_name}`,
        }));
        setAccounts(formattedAccounts);
      } catch (error) {
        console.error("Error fetching accounts:", error);
      }
    };
    fetchAccounts();
  }, [backendUrl]);

  // Fetch parent names

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Ensure that the form is validated before submission

    const submissionData = {
      ...formData,
      id: row.id,
      created_by: userId || "defaultUserId",
    };

    if (!submissionData.id) {
      console.error("ID is missing in submissionData");
      alert("ID is missing, cannot submit!");
      return;
    }

    const url = `${backendUrl}/assets/assets/updateAssetsRevaluation?assets_id=${submissionData.id}`;
    console.log("Request URL:", url);

    try {
      await axios.put(url, submissionData);
      alert("Revaluation  successfull!");
      window.location.reload(); // Refresh the page to reflect changes
    } catch (error) {
      console.error("Error updating Revaluation:", error);
      alert("An error occurred while updating the Revaluation.");
    }
  };

  return (
    <div className="p-6 bg-white rounded shadow">
      <div className="max-w-8xl mx-auto bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-2xl font-semibold mb-4 text-center">
          Assets Revaluation
        </h2>
      </div>

      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center space-x-4 w-2/3 mx-auto"></div>
      </div>

      <form action="" className="px-28 mt-8">
        <h2 className="text-2xl font-semibold mb-4 text-center">
          Parent Asset
        </h2>
        <div className="grid grid-cols-3 gap-6 text-[20px]">
          <div className="w-full">
            <label className="block text-gray-700 mb-2">Assets ID</label>
            <input
              type="text"
              name="assets_id"
              value={formData.assets_id}
              readOnly // Corrected binding to formData
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div className="w-full">
            <label className="block text-gray-700 mb-2">
              Parent Assets Name
            </label>
            <input
              type="text"
              name="parent_asset_name"
              value={formData.parent_asset_name || ""}
              readOnly
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>

          <div className="w-full">
            <label className="block text-gray-700 mb-2">
              Parent Department
            </label>
            <input
              type="text"
              name="parent_asset_name"
              value={formData.parent_department || ""}
              readOnly
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div className="w-full">
            <label htmlFor="current_value" className="block text-gray-500 mb-2">
              Current Value
            </label>
            <input
              id="current_value"
              name="current_value"
              type="text"
              className="w-full p-2 border border-gray-300 rounded"
              value={formData.current_value || ""}
              readOnly
              onChange={handleInputChange}
            />
          </div>

          <div className="w-full">
            <label className="block text-gray-700 mb-2">Revaluted Value </label>
            <input
              type="number"
              name="revaluated_value"
              value={formData.revaluated_value}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>

          <div className="w-full">
            <label htmlFor="assets_id" className="block text-gray-500 mb-2">
              Surplus ID
            </label>
            <ReactSelect
              id="surplus_account_id"
              name="surplus_account_id"
              options={accounts}
              getOptionLabel={(e) => `${e.name}`} // Display name as label
              getOptionValue={(e) => e.id} // Value will be the account id
              onChange={(selectedOption) =>
                setFormData((prevDetails) => ({
                  ...prevDetails,
                  surplus_account_id: selectedOption ? selectedOption.id : "",
                }))
              }
              value={accounts.find(
                (acc) => acc.id === formData.surplus_account_id
              )}
              className="w-full p-2 border rounded-lg"
            />
          </div>
        </div>

        {formData.children && formData.children.length > 0 && (
          <div>
            {formData.children.map((child, index) => (
              <div key={index}>
                {" "}
                <h2 className="text-2xl font-semibold mb-4 text-center">
                  Child Asset {index + 1}
                </h2>
                <div className="grid grid-cols-3 gap-6 text-[20px]">
                  <div className="w-full">
                    <label className="block text-gray-700 mb-2">
                      Assets ID
                    </label>
                    <input
                      type="text"
                      name="assets_id"
                      value={child.assets_id}
                      readOnly
                      onChange={handleInputChange}
                      className="w-full p-2 border border-gray-300 rounded"
                    />
                  </div>

                  <div className="w-full">
                    <label className="block text-gray-700 mb-2">
                      Child Asset Name
                    </label>
                    <input
                      type="text"
                      name="child_asset_name"
                      value={child.child_asset_name}
                      readOnly
                      onChange={handleInputChange}
                      className="w-full p-2 border border-gray-300 rounded"
                    />
                  </div>

                  <div className="w-full">
                    <label className="block text-gray-700 mb-2">
                      Child Department
                    </label>
                    <input
                      type="text"
                      name="child_department"
                      value={child.child_department}
                      readOnly // Corrected binding to formData
                      onChange={handleInputChange}
                      className="w-full p-2 border border-gray-300 rounded"
                    />
                  </div>
                  {/* Current Value */}
                  <div className="w-full">
                    <label className="block text-gray-700 mb-2">
                      Current Value
                    </label>
                    <input
                      type="number"
                      name="current_value"
                      value={child.current_value}
                      readOnly // Corrected binding to formData
                      onChange={handleInputChange}
                      className="w-full p-2 border border-gray-300 rounded"
                    />
                  </div>
                  <div className="w-full">
                    <label className="block text-gray-700 mb-2">
                      Revaluted Value{" "}
                    </label>
                    <input
                      className="w-full p-2 border border-gray-300 rounded"
                      type="text"
                      value={child.revaluated_value}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          children: formData.children.map((c, i) =>
                            i === index
                              ? { ...c, revaluated_value: e.target.value }
                              : c
                          ),
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}

        <button
          className="px-4 py-2 bg-black text-white rounded-md mt-12 grid justify-items-end"
          type="button"
          onClick={handleSubmit}
        >
          Save
        </button>
      </form>
    </div>
  );
}

export default AssetsRevaluation;
