import { Link, Outlet, useLocation } from "react-router-dom";

const AccountAuthorizationPage = () => {
    const location = useLocation();

    // Function to check if the current path matches a link
    const isActiveTab = (path) => location.pathname === path;

    return (
        <div className="container mx-auto p-6 bg-gray-50 shadow-lg rounded-md">
            {/* Tabs Header */}
            <div className="flex justify-center border-b-2 border-gray-200 mb-6 gap-2">
                <Link
                    to="/finance-management/account-authorization/assigen-account"
                    className={`px-6 py-3 text-lg transition-colors duration-200 ${
                        isActiveTab("/finance-management/account-authorization/assigen-account")
                            ? "border-b-4 border-blue-500 text-blue-600 font-semibold"
                            : "border-b-4 border-transparent text-gray-700 hover:border-blue-500 hover:text-blue-600"
                    }`}
                >
                    Account Assign
                </Link>
                <Link
                    to="/finance-management/account-authorization"
                    className={`px-6 py-3 text-lg transition-colors duration-200 ${
                        isActiveTab("/finance-management/account-authorization")
                            ? "border-b-4 border-blue-500 text-blue-600 font-semibold"
                            : "border-b-4 border-transparent text-gray-700 hover:border-blue-500 hover:text-blue-600"
                    }`}
                >
                    Account Authorization Common
                </Link>
                <Link
                    to="/finance-management/account-authorization/account-authorization-pos"
                    className={`px-6 py-3 text-lg transition-colors duration-200 ${
                        isActiveTab("/finance-management/account-authorization/account-authorization-pos")
                            ? "border-b-4 border-blue-500 text-blue-600 font-semibold"
                            : "border-b-4 border-transparent text-gray-700 hover:border-blue-500 hover:text-blue-600"
                    }`}
                >
                    Account Authorization Branch
                </Link>
                <Link
                    to="/finance-management/account-authorization/account-authorization-category"
                    className={`px-6 py-3 text-lg transition-colors duration-200 ${
                        isActiveTab("/finance-management/account-authorization/account-authorization-category")
                            ? "border-b-4 border-blue-500 text-blue-600 font-semibold"
                            : "border-b-4 border-transparent text-gray-700 hover:border-blue-500 hover:text-blue-600"
                    }`}
                >
                    Account Authorization Inventory Item Category
                </Link>
                <Link
                    to="/finance-management/account-authorization/account-authorization-cash_flow"
                    className={`px-6 py-3 text-lg transition-colors duration-200 ${
                        isActiveTab("/finance-management/account-authorization/account-authorization-cash_flow")
                            ? "border-b-4 border-blue-500 text-blue-600 font-semibold"
                            : "border-b-4 border-transparent text-gray-700 hover:border-blue-500 hover:text-blue-600"
                    }`}
                >
                    Account Authorization Cash Flow
                </Link>
            </div>

            {/* Content Section */}
            <Outlet />
        </div>
    );
};

export default AccountAuthorizationPage;
