import React, { useState, useEffect } from "react";
import axios from "axios";
import {useLocation} from "react-router-dom";

const UnreconciledCheques = () => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL_FINANCE;
    const [cheques, setCheques] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const location = useLocation();
    const { selectedAccount, from_date, to_date } = location.state || {};

    const fetchUnreconciledCheques = async (account, from_date, to_date) => {
        try {
            const response = await axios.get(`${backendUrl}/banking/getUnreconciledCheques`,{
                params: {
                    bank_account: account,
                    from_date: from_date,
                    to_date: to_date,
                }, // Pass query parameter
            });
            setCheques(response.data.unreconciledCheques || []);
            setTotalAmount(response.data.totalAmount || 0);
        } catch (error) {
            console.error("Error fetching unreconciled cheques:", error);
        }
    };

    useEffect(() => {
        if (selectedAccount) {
            fetchUnreconciledCheques(selectedAccount.value, from_date, to_date);
        }
    }, [selectedAccount]);

    return (
        <div className="bg-gray-100 min-h-screen p-6">
            <div className="max-w-6xl mx-auto bg-white p-6 rounded-lg shadow-lg">
                <h2 className="text-2xl font-semibold mb-4">Unreconciled Cheques</h2>
                <table className="min-w-full bg-white">
                    <thead>
                    <tr className="w-full bg-gray-50">
                        <th className="text-left py-3 px-4 uppercase font-semibold text-sm">GL Transaction ID</th>
                        <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Date</th>
                        <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Description</th>
                        <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Cheque No</th>
                        <th className="text-right py-3 px-4 uppercase font-semibold text-sm">Amount</th>
                    </tr>
                    </thead>
                    <tbody>
                    {cheques.map((cheque, index) => (
                        <tr key={index} className="border-t border-gray-200">
                            <td className="py-3 px-4">{cheque.GLTransactionID}</td>
                            <td className="py-3 px-4">{cheque.date}</td>
                            <td className="py-3 px-4">{cheque.description}</td>
                            <td className="py-3 px-4">{cheque.cheque_no}</td>
                            <td className="py-3 px-4 text-right">{cheque.amount}</td>
                        </tr>
                    ))}
                    {cheques.length === 0 && (
                        <tr>
                            <td colSpan="5" className="text-center py-4 text-gray-500">
                                No unreconciled cheques found.
                            </td>
                        </tr>
                    )}
                    </tbody>
                    <tfoot>
                    <tr className="bg-gray-100">
                        <td colSpan="4" className="text-right py-3 px-4 font-semibold">Total Amount</td>
                        <td className="text-right py-3 px-4 font-semibold">{totalAmount}</td>
                    </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    );
};

export default UnreconciledCheques;
