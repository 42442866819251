import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactSelect from "react-select";
import Cookies from "js-cookie";

const AccountAuthorizationCategory = () => {
    const [categories, setCategories] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [selectedAccounts, setSelectedAccounts] = useState({});
    const [defaultAccounts, setDefaultAccounts] = useState({});
    const [notification, setNotification] = useState(null);
    const backendUrl = process.env.REACT_APP_BACKEND_URL_FINANCE;
    //const backendUrl = `http://localhost:8089/v1/finance`;

    // Fetch categories with subcategories
    const fetchCategories = async () => {
        try {
            const response = await axios.get(`${backendUrl}/account/accountAuthorization/category/getCategoryLevels`);
            setCategories(response.data);
        } catch (error) {
            console.error("Error fetching categories:", error);
        }
    };

    // Fetch financial accounts
    const fetchAccounts = async () => {
        try {
            const response = await axios.get(`${backendUrl}/account/getAllAccountsByFilters`, {
                params: { is_enabled: 1 },
            });
            setAccounts(
                response.data.data.map((acc) => ({
                    value: acc.account_id,
                    label: `${acc.account_code} - ${acc.account_name}`,
                }))
            );
        } catch (error) {
            console.error("Error fetching accounts:", error);
        }
    };

    // Fetch current account mappings
    const fetchAccountMappings = async () => {
        try {
            const response = await axios.get(
                `${backendUrl}/account/accountAuthorization/category/getCategoryAccountLevels`
            );

            const formattedMappings = response.data.reduce((acc, item) => {
                acc[item.category_id] = item.accounts;
                return acc;
            }, {});

            setSelectedAccounts(formattedMappings);
            setDefaultAccounts(formattedMappings);
        } catch (error) {
            console.error("Error fetching account mappings:", error);
        }
    };

    // Handle account selection
    const handleAccountChange = (categoryId, subCategoryId, value) => {
        setSelectedAccounts((prev) => ({
            ...prev,
            [categoryId]: {
                ...prev[categoryId],
                [subCategoryId || "main"]: value,
            },
        }));
    };

    // Prepare payload for submission
    const preparePayload = (categoryId) => {
        const selectedCategoryAccounts = selectedAccounts[categoryId] || {};
        const defaultCategoryAccounts = defaultAccounts[categoryId] || {};

        const combinedAccounts = {
            ...defaultCategoryAccounts,
            ...selectedCategoryAccounts,
        };

        const payload = {
            category_id: categoryId,
            accounts: Object.keys(combinedAccounts).reduce((acc, field) => {
                acc[field] = combinedAccounts[field] !== undefined ? combinedAccounts[field] : null;
                return acc;
            }, {}),
            action_by: Cookies.get("employee_no"),
        };

        return payload;
    };

    // Save configuration
    const handleSubmit = async (categoryId) => {
        const payload = preparePayload(categoryId);

        try {
            await axios.post(`${backendUrl}/account/accountAuthorization/category/updateCategoryWiseAccAuth`, payload);
            setNotification({ type: "success", message: "Accounts updated successfully!" });
        } catch (error) {
            console.error("Error saving account configurations:", error);
            setNotification({ type: "error", message: "Error updating accounts!" });
        }
    };

    useEffect(() => {
        fetchCategories();
        fetchAccounts();
        fetchAccountMappings();
    }, []);

    return (
        <div className="container mx-auto p-6 pt-0 bg-gray-50">
            {notification && (
                <div
                    className={`p-4 mb-4 rounded ${
                        notification.type === "success" ? "bg-green-100 text-green-800" : "bg-red-100 text-red-800"
                    }`}
                >
                    {notification.message}
                </div>
            )}
            <h4 className="text-2xl font-bold text-center mb-6">
                Configure category wise inventory accounts for automation scenarios
            </h4>
            {categories.map((category) => (
                <div key={category.category_id} className="mb-6 bg-white rounded-lg shadow p-6">
                    <h2 className="text-xl font-semibold border-b pb-2 mb-4">{category.item_category_name}</h2>

                    <div className="mb-6">
                        <label className="block mb-2 text-sm font-medium">Account
                            for {category.item_category_name}</label>
                        <ReactSelect
                            options={accounts}
                            value={
                                accounts.find(
                                    (acc) => acc.value === selectedAccounts[category.category_id]?.main
                                ) || null
                            }
                            onChange={(option) =>
                                handleAccountChange(category.category_id, null, option?.value)
                            }
                            isClearable
                            placeholder="Select Account"
                        />
                    </div>

                    {category.sub_categories && category.sub_categories.length > 0 && (
                        <div className="ml-4 pl-4 border-l">
                            <h3 className="text-lg font-semibold mb-4">Subcategories</h3>
                            <div className="grid grid-cols-2 gap-x-4">
                                {category.sub_categories.map((subCategory) => (
                                    <div key={subCategory.sub_category_id} className="mb-6">
                                        <label className="block mb-2 text-sm font-medium">
                                            Account for {subCategory.item_sub_category_name}
                                        </label>
                                        <ReactSelect
                                            options={accounts}
                                            value={
                                                accounts.find(
                                                    (acc) =>
                                                        acc.value ===
                                                        selectedAccounts[category.category_id]?.[subCategory.sub_category_id]
                                                ) || null
                                            }
                                            onChange={(option) =>
                                                handleAccountChange(
                                                    category.category_id,
                                                    subCategory.sub_category_id,
                                                    option?.value
                                                )
                                            }
                                            isClearable
                                            placeholder="Select Account"
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    <div className="flex justify-end">
                        <button
                            onClick={() => handleSubmit(category.category_id)}
                            className="mt-4 bg-blue-600 text-white px-4 py-2 rounded shadow hover:bg-blue-700"
                        >
                            Save Configuration
                        </button>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default AccountAuthorizationCategory;
