import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import AccountsTable from "./AccountsTable";

const CashFlow = () => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL_FINANCE;
    const [data, setData] = useState([]);
    const [totals, setTotals] = useState({
        operating: 0,
        investing: 0,
        financing: 0,
        cashEquivalent: 0,
        netCashFlow: 0,
    });
    const [startDate, setStartDate] = useState(
        new Date(new Date().getFullYear(), 0, 1).toISOString().split("T")[0]
    );
    const [endDate, setEndDate] = useState(
        new Date(new Date().getFullYear(), 11, 31).toISOString().split("T")[0]
    );
    const [filterDates, setFilterDates] = useState({
        start: new Date(new Date().getFullYear(), 0, 1).toISOString().split("T")[0],
        end: new Date(new Date().getFullYear(), 11, 31).toISOString().split("T")[0],
    });

    const fetchCashFlowData = async () => {
        try {
            const response = await axios.get(`${backendUrl}/financeReports/getCashFlowStatement`, {
                params: { start_date: filterDates.start, end_date: filterDates.end },
            });
            setData(response.data.structuredData);
            setTotals({
                operating: response.data.operating,
                investing: response.data.investing,
                financing: response.data.financing,
                cashEquivalent: response.data.cashEquivalent,
                netCashFlow: response.data.netCashFlow,
            });
        } catch (err) {
            console.error("Error fetching Cash Flow Statement", err);
        }
    };

    useEffect(() => {
        fetchCashFlowData();
    }, [filterDates]);

    const applyDateFilter = () => {
        setFilterDates({ start: startDate, end: endDate });
    };

    const flattenData = (structuredData) => {
        const flatData = [];

        const recursivelyFlatten = (items, level = 1) => {
            items.forEach((item) => {
                if (level <= 2) {
                    flatData.push({
                        account_code: item.account_code,
                        account_name: item.account_name,
                        amount: item.amount,
                    });

                    // Only process children if current level is less than 2
                    if (level < 2 && item.children && item.children.length > 0) {
                        recursivelyFlatten(item.children, level + 1);
                    }
                }
            });
        };

        structuredData.forEach((group) => recursivelyFlatten(group));
        return flatData;
    };


    const downloadReportAsExcel = () => {
        if (!data || data.length === 0) {
            console.error("No data available to export.");
            return;
        }

        const flattenedData = flattenData(data);

        // Define headers explicitly
        const headers = ["Account Code", "Account Name", "Amount"];

        // Map data to match headers
        const dataWithHeaders = flattenedData.map((item) => [
            item.account_code || "",
            item.account_name || "",
            item.amount !== null && item.amount !== undefined ? item.amount : 0,
        ]);

        // Add headers to the top of the data
        const excelData = [headers, ...dataWithHeaders];

        // Create worksheet and workbook
        const worksheet = XLSX.utils.aoa_to_sheet(excelData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "CashFlow");

        // Write to buffer and save as file
        const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
        const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
        saveAs(blob, "CashFlowStatement.xlsx");
    };

    return (
        <div className="max-w-7xl mx-auto px-6 py-8">
            <h1 className="text-3xl font-semibold text-gray-800 mb-6">Cash Flow Statement</h1>
            <div className="flex gap-4 mb-6">
                <div className="bg-white shadow-md rounded-lg p-4 w-1/4">
                    <h3 className="font-medium text-gray-700">Net Cash Flow</h3>
                    <p className="text-2xl font-bold text-green-600">
                        {parseFloat(totals.netCashFlow).toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        })} LKR
                    </p>
                </div>
                <div className="bg-white shadow-md rounded-lg p-4 w-1/4">
                    <h3 className="font-medium text-gray-700">Operating Activities</h3>
                    <p className="text-2xl font-bold">
                        {parseFloat(totals.operating).toLocaleString()} LKR
                    </p>
                </div>
                <div className="bg-white shadow-md rounded-lg p-4 w-1/4">
                    <h3 className="font-medium text-gray-700">Investing Activities</h3>
                    <p className="text-2xl font-bold">
                        {parseFloat(totals.investing).toLocaleString()} LKR
                    </p>
                </div>
                <div className="bg-white shadow-md rounded-lg p-4 w-1/4">
                    <h3 className="font-medium text-gray-700">Financing Activities</h3>
                    <p className="text-2xl font-bold">
                        {parseFloat(totals.financing).toLocaleString()} LKR
                    </p>
                </div>
            </div>

            <div className="flex gap-4 mb-6">
                <input
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    className="border border-gray-300 rounded-lg p-2"
                />
                <input
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    className="border border-gray-300 rounded-lg p-2"
                />
                <button
                    onClick={applyDateFilter}
                    className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                >
                    Apply Filter
                </button>
                <button
                    onClick={downloadReportAsExcel}
                    className="px-4 py-2 bg-green-700 text-white rounded-lg hover:bg-green-600 ml-auto"
                >
                    Download as Excel
                </button>
            </div>

            <div className="bg-white shadow-md rounded-lg p-4">
                <AccountsTable data={data} totals={totals} />
            </div>
        </div>
    );
};

export default CashFlow;
